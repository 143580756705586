exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-anytimedonation-js": () => import("./../../../src/pages/anytimedonation.js" /* webpackChunkName: "component---src-pages-anytimedonation-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-commentviewer-js": () => import("./../../../src/pages/commentviewer.js" /* webpackChunkName: "component---src-pages-commentviewer-js" */),
  "component---src-pages-documents-creators-guideline-js": () => import("./../../../src/pages/documents/creators-guideline.js" /* webpackChunkName: "component---src-pages-documents-creators-guideline-js" */),
  "component---src-pages-documents-privacy-policy-js": () => import("./../../../src/pages/documents/privacy-policy.js" /* webpackChunkName: "component---src-pages-documents-privacy-policy-js" */),
  "component---src-pages-documents-specified-commercial-transactions-act-js": () => import("./../../../src/pages/documents/specified-commercial-transactions-act.js" /* webpackChunkName: "component---src-pages-documents-specified-commercial-transactions-act-js" */),
  "component---src-pages-documents-term-of-service-js": () => import("./../../../src/pages/documents/term-of-service.js" /* webpackChunkName: "component---src-pages-documents-term-of-service-js" */),
  "component---src-pages-download-js": () => import("./../../../src/pages/download.js" /* webpackChunkName: "component---src-pages-download-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-screen-js": () => import("./../../../src/pages/screen.js" /* webpackChunkName: "component---src-pages-screen-js" */),
  "component---src-pages-tutorial-js": () => import("./../../../src/pages/tutorial.js" /* webpackChunkName: "component---src-pages-tutorial-js" */),
  "component---src-templates-article-list-template-js": () => import("./../../../src/templates/article-list-template.js" /* webpackChunkName: "component---src-templates-article-list-template-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-category-template-js": () => import("./../../../src/templates/category-template.js" /* webpackChunkName: "component---src-templates-category-template-js" */),
  "component---src-templates-tutorial-post-js": () => import("./../../../src/templates/tutorial-post.js" /* webpackChunkName: "component---src-templates-tutorial-post-js" */)
}

